import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    positions: null,
};

export const PRESENT_DATE = '9999-12-31';

export default {
    state,
    getters: {
        getPositionForm: state => position => {
            return new Form(_.assignWith({}, {
                id: null,
                primary: false,
                startMonthUnknown: false,
                endMonthUnknown: false,
                positionType: 'permanent',
                career: null,
                title: '',
                companyName: '',
                industry: null,
                location: null,
                teamSizeId: null,
                nedClassificationId: 1,
                summary: '',
                startDate: null,
                endDate: null,
                achievements: [],
                skills: [],
                companyTypes: [],
            }, (no, def, key) => {
                if (!position) {
                    return def;
                }
                if (key === 'companyName') {
                    return position.company?.name || def;
                }
                // All fields with `id` in the name need to fetch
                // the nested id from the object, except for
                // teamSizeId
                if (key !== 'teamSizeId') {
                    key = key.replace(/_id$/, '.id').replace('ned_', '');
                }
                let result = _.get(position, key, def);
                if (key === 'positionType' && result === null) {
                    result = def;
                }
                if (_.isArray(result)) {
                    if (key === 'companyTypes') {
                        result = _.map(result, 'id');
                    } else {
                        result = _.clone(result);
                    }
                } else if (_.isObject(result)) {
                    result = _.get(result, 'id');
                }
                return result;
            }));
        },
    },
    mutations: {
        ...make.mutations(state),
        'UPDATE_PRIMARY_POSITION'(state, primary) {
            state.positions.forEach(position => {
                if (position.id !== primary.id && position.primary) {
                    position.primary = false;
                }
                if (position.id === primary.id) {
                    position.primary = true;
                }
            });
        },
    },
    actions: {
        async savePosition({ commit, state, rootGetters }, form) {
            let exists = !!form.id;
            let method = exists ? 'put' : 'post';
            const position = await form[method](rootGetters['user/apiUrl']('positions', form.id), {
                scrollToFirstError: true,
            });

            let index = _.findIndex(state.positions, ['id', position.id]);
            let positions;

            if (~index) {
                positions = [...state.positions.slice(0, index), position, ...state.positions.slice(index + 1)];
            } else {
                positions = [...state.positions, position];
            }

            commit('SET_POSITIONS', positions);

            if (position.primary) {
                commit('UPDATE_PRIMARY_POSITION', position);
            }
            await this.dispatch('user/stats/refreshCompletion');
        },
        async removePosition({ commit, state, rootGetters }, position) {
            let index = _.findIndex(state.positions, ['id', position.id]);
            if (~index) {
                commit('SET_POSITIONS', [...state.positions.slice(0, index), ...state.positions.slice(index + 1)]);
            }

            await nalRequest.delete(rootGetters['user/apiUrl']('positions', position.id));
            await this.dispatch('user/stats/refreshCompletion');
        },
    },
};
